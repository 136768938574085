import classes from './Admission.module.css';
import sticker from '../asset/sticker.jpeg';

import prog1 from '../asset/prog1.png';
import prog2 from '../asset/prog2.png';
import prog3 from '../asset/prog3.png';
import prog4 from '../asset/prog4.png';
import prog5 from '../asset/prog5.png';

const Admission = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>SCHOOL
                <span className={classes.headcol}> ADMISSION</span> OPEN</p>

            <div className={classes.divHold}>
                <div className={classes.left}>
                    <img src={sticker} className={classes.sticker}></img>
                </div>
                <div className={classes.right}>
                    <p className={classes.txt}>Dear parents, Do you want to give the best education & best skill to your children? Obviously yes! 😊
                        So here is the best preschool for your children, The Kalpataru preschool. We are providing modern education & modern technology for today’s genius kids.</p>

                    <p className={classes.txt2}>The various services we offer include classroom & play area. Thematic & learning activities using innovative & creative methods. Unique & innovative curriculum design for Indian children & their way of learning. Imparting values necessary for a child to grow as a human being with a good moral character.</p>

                    <div className={classes.progHold}>
                        <p className={classes.progHead}>Our Programs</p>

                        <div className={classes.cardHold}>
                            <div className={classes.card}>
                                <img src={prog1} alt='' className={classes.image}></img>
                                <p className={classes.txt}>Playgroup</p>
                            </div>

                            <div className={classes.card}>
                                <img src={prog2} alt='' className={classes.image}></img>
                                <p className={classes.txt}>Nursery</p>
                            </div>

                            <div className={classes.card}>
                                <img src={prog3} alt='' className={classes.image}></img>
                                <p className={classes.txt}>Junior k.g.</p>
                            </div>

                            <div className={classes.card}>
                                <img src={prog4} alt='' className={classes.image}></img>
                                <p className={classes.txt}>Senior k.g.</p>
                            </div>

                            <div className={classes.card}>
                                <img src={prog5} alt='' className={classes.image}></img>
                                <p className={classes.txt}>Daycare</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admission;