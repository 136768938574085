import React, { Component } from "react";
import Slider from "react-slick";

import classes from './WonderSlide.module.css';
import img1 from '../asset/gallery1.jpg';
import img2 from '../asset/gallerty2.jpg';
import img3 from '../asset/gallery3.jpg';
import img4 from '../asset/gallery4.jpeg';

export default class WonderSlide extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear"
        };
        return (
            <div>

                <Slider {...settings}>
                    <div>
                        <img src={img1} className={classes.image}></img>
                    </div>
                    <div>
                        <img src={img2} className={classes.image}></img>
                    </div>
                    <div>
                        <img src={img3} className={classes.image}></img>
                    </div>
                    <div>
                        <img src={img4} className={classes.image}></img>
                    </div>
                    <div>
                        <img src={img1} className={classes.image}></img>
                    </div>
                    <div>
                        <img src={img2} className={classes.image}></img>
                    </div>
                </Slider>
            </div>
        );
    }
}