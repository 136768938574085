import { useEffect } from 'react';
import classes from './Contact.module.css';
import bird from '../asset/birds.png';
import { Link } from 'react-router-dom';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={classes.hold}>
            <div className={classes.left}>
                <img src={bird} alt='bird' className={classes.bird}></img>
            </div>
            <div className={classes.right}>
                <p className={classes.txt1}>Contact Us for more Information</p>
                <p className={classes.txt2}>Our main priority is to provide excellent service so that parents can be 100% sure their kids are safe and taken care of!</p>
                <div className={classes.btnHold}>
                    <Link to='/contact' className='linker'>
                        <button className={classes.btn}>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Contact;