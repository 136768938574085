import classes from './Slide1.module.css';

const Slide1 = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.txthold}>
                <p className={classes.head1}>A Perfect Place To Learn</p>
                <p className={classes.head2}> With Fun</p>
            </div>
        </div>
    )
}

export default Slide1;