import classes from './Mission.module.css';
import child from '../asset/child.jpg';

const Mission = () => {
    return (<div className={classes.container}>

        <div className={classes.hold}>
            <div className={classes.left}>
                <p className={classes.head}>We are on a
                    <span className={classes.headcolor}> Mission</span></p>
                <p className={classes.txt}>OUR MISSION AT KALPATARU IS TO DEVELOP THE “WHOLE CHILD”, WHERE THE CHILDREN GAIN CONFIDENCE THROUGH THE ACQUISITION OF BASIC SKILLS. WE ESTABLISH CHILDREN LOVE FOR LEARNING, STRONG WORK ETHICS, CREATIVITY, RESPECT AND COMPASSION FOR OTHERS.</p>
                <p className={classes.txt}>Our Academy follows the fundamental teaching of Dr. Maria Montessori, while incorporating modern approaches and technology in the classrooms. Our Teaching Method is designed to build upon the individual skills and knowledge that will be required in the pre-school and kindergarten years. We make sure that we make the most of each child’s learning potentials.</p>
            </div>
            <div className={classes.right}>
                <img src={child} className={classes.cld}></img>
            </div>
        </div>
    </div>
    )
}

export default Mission;