import { useEffect } from 'react';
import classes from './Gallery.module.css';
import img1 from '../components/Home/Wonderland/gallery/16.jpg';
import img2 from '../components/Home/Wonderland/gallery/17.jpg';
import img3 from '../components/Home/Wonderland/gallery/18.jpg';
import img4 from '../components/Home/Wonderland/gallery/19.jpg';
import img5 from '../components/Home/Wonderland/gallery/20.jpg';
import img6 from '../components/Home/Wonderland/gallery/21.jpg';
import img7 from '../components/Home/Wonderland/gallery/22.jpg';
import img8 from '../components/Home/Wonderland/gallery/23.jpg';
import img9 from '../components/Home/Wonderland/gallery/24.jpg';
import img10 from '../components/Home/Wonderland/gallery/25.jpg';
import img11 from '../components/Home/Wonderland/gallery/1.jpg';
import img12 from '../components/Home/Wonderland/gallery/2.jpg';
import img13 from '../components/Home/Wonderland/gallery/3.jpg';
import img14 from '../components/Home/Wonderland/gallery/4.jpg';
import img15 from '../components/Home/Wonderland/gallery/5.jpg';
import img16 from '../components/Home/Wonderland/gallery/6.jpg';
import img17 from '../components/Home/Wonderland/gallery/7.jpg';
import img18 from '../components/Home/Wonderland/gallery/8.jpg';
import img19 from '../components/Home/Wonderland/gallery/9.jpg';
import img20 from '../components/Home/Wonderland/gallery/10.jpg';
import img21 from '../components/Home/Wonderland/gallery/11.jpg';
import img22 from '../components/Home/Wonderland/gallery/12.jpg';
import img23 from '../components/Home/Wonderland/gallery/13.jpg';
import img24 from '../components/Home/Wonderland/gallery/14.jpg';
import img25 from '../components/Home/Wonderland/gallery/15.jpg';




const Gallery = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const imageArr = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10,
        img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
        img21, img22, img23, img24, img25];
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Our Gallery</p>
            <p className={classes.head2}>Incredible India</p>

            <div className={classes.imghold}>
                {imageArr.map(
                    (pic) => <img src={pic} className={classes.icon}></img>)};
            </div>
        </div>
    )
}

export default Gallery;