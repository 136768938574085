import classes from './Card.module.css';


const Card = (props) => {
    return (
        <div className={classes.card}>
            <div className={classes.headHold}>
                <img src={props.image} className={classes.icon}></img>
                <p className={classes.head}>{props.head}</p>
            </div>
            <p className={classes.txt}>{props.txt}</p>
        </div>
    )
}

export default Card;