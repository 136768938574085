import classes from './AdmissionFeature.module.css';//4DB400
import feat1 from '../Home/asset/feat-classroom.png';
import feat2 from '../Home/asset/feat-teacher.png';
import feat3 from '../Home/asset/feat-society.png';
import feat4 from '../Home/asset/feat-skills.png';
import feat5 from '../Home/asset/feat-computer-lab.png';
import feat6 from '../Home/asset/feat-playground.png';
import feat7 from '../Home/asset/feat-park.png';
import feat8 from '../Home/asset/feat-teamwork.png';
import feat9 from '../Home/asset/feat-clean.png';

const AdmissionFeature = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Kalpataru Preschool
                <span className={classes.headcolor}> Features</span></p>

            <div className={classes.featureHold}>
                <div className={classes.feature}>
                    <img src={feat1} alt='' className={classes.icon}></img>
                    <p className={classes.txt}>Healthy Classrooms</p>
                </div>
                <div className={classes.feature}>
                    <img src={feat2} alt='' className={classes.icon}></img>
                    <p className={classes.txt}>Professional teachers</p>
                </div>
                <div className={classes.feature}>
                    <img src={feat3} alt='' className={classes.icon}></img>
                    <p className={classes.txt}>Big activity area</p>
                </div>
                <div className={classes.feature}>
                    <img src={feat4} alt='' className={classes.icon}></img>
                    <p className={classes.txt}>Skill development classes</p>
                </div>
                <div className={classes.feature}>
                    <img src={feat5} alt='' className={classes.icon}></img>
                    <p className={classes.txt}>Tech lab</p>
                </div>
                <div className={classes.feature}>
                    <img src={feat6} alt='' className={classes.icon}></img>
                    <p className={classes.txt}>Playpark</p>
                </div>
                <div className={classes.feature}>
                    <img src={feat7} alt='' className={classes.icon}></img>
                    <p className={classes.txt}>Adventure park</p>
                </div>
                <div className={classes.feature}>
                    <img src={feat8} alt='' className={classes.icon}></img>
                    <p className={classes.txt}>Supportive staff</p>
                </div>
                <div className={classes.feature}>
                    <img src={feat9} alt='' className={classes.icon}></img>
                    <p className={classes.txt}>Clean Surrounding</p>
                </div>
            </div>
        </div>
    )
}

export default AdmissionFeature;