import classes from './Wonder.module.css';
import WonderSlide from './WonderSlide';

const Wonder = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Image
                <span className={classes.headcol}> Gallery </span>Of Our
                <span className={classes.headcol2}> Wonderland</span></p>

            <div>
                <WonderSlide></WonderSlide>
            </div>
        </div>
    )
}

export default Wonder;