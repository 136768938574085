import { useEffect } from 'react';
import classes from './Nursery.module.css';
import play1 from './asset/nursery1.jpg';
import img1 from './asset/blocks.png';
import img2 from './asset/care.png';
import img3 from './asset/chalkboard.png';
import board from './asset/chalkboard.png';
import blackboard from './asset/blackboard.png';
import baby from './asset/baby-girl.png';
import abc from '../components/Home/asset/abc.png';
import { Link } from 'react-router-dom';

const Nursery = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={classes.hold}>
            <div className={classes.headHold}>
                <p className={classes.head}>Nursery</p>
            </div>

            <div className={classes.divhold}>
                <div className={classes.left}>
                    <img src={play1} className={classes.play1}></img>
                </div>
                <div className={classes.right}>
                    <p className={classes.head1}>About Our
                        <span className={classes.headcolor}> Nursery</span></p>

                    <p className={classes.txt1}>This program is designed to cater to the developmental requirements of children between 3-4 yrs. We help them build a strong base in phonics, to improve their reading skills.</p>
                    <p className={classes.txt1}>Children learn to speak the alphabet in a sequential, rhyming manner and also write alphabets in the upper case. They are encouraged to frame and speak simple sentences using words learnt through themes. </p>
                    <p className={classes.txt1}>Our preschool program encourages their creativity and imaginative skills, through story-telling sessions and circle-time sessions. The understanding of numbers and pre-math skills builds critical thinking and problem solving skills.</p>
                    <p className={classes.txt1}>Through role plays and group games, children learn more about themselves, others and their environment.</p>
                    <Link to='/contact' className='linker'>
                        <button className={classes.btn}>Enroll Now</button>
                    </Link>
                </div>
            </div>



            <div className={classes.aptitudeHold}>
                <p className={classes.aptitudeHead}>The
                    <span className={classes.headcolor}> nursery </span>, is a hugely important stage for your child’s learning, and it’s a really fun one too!
                </p>

                <p className={classes.aptitudeTxt}>At the nursery stage of children’s’ learning, your child will be mainly learning through play-based activities. They will also be learning about routine and developing early literacy and maths skills, learning about the world around them, and learning social skills.</p>

                <div className={classes.txtHold}>
                    <div className={classes.aptitudeLeft}>
                        <ul className={classes.listHold}>
                            <li className={classes.list}>Pre math and rationale based aptitudes like recognizing designs, sequencing, size, and amount</li>
                            <li className={classes.list}>Early perusing aptitudes as they are urged to become autonomous perusers.</li>
                            <li className={classes.list}>Language aptitudes through composition and perusing entire words.</li>
                        </ul>
                    </div>
                    <div className={classes.aptitudeRight}>
                        <ul className={classes.listHold}>
                            <li className={classes.list}>your child will probably be encouraged to handle and look at books independently and to begin to learn about how stories are structured.</li>
                            <li className={classes.list}>Your child will be encouraged to draw, paint and make to develop control and hand-eye co-ordination.</li>
                            <li className={classes.list}>They may begin to learn to copy the letters in their name.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={classes.feeHold}>
                <p className={classes.feeHead}>What You
                    <span className={classes.headcolor}> Get</span> For The
                    <span className={classes.headcolor}> Fees</span> You Pay</p>

                <div className={classes.cardHold}>
                    <div className={classes.card1}>
                        <img src={img1} className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Holistic Curriculum</p>
                        <p className={classes.cardTxt}>as the name proposes covers a wide scope of abilities, showing apparatuses, objectives and desires for kids' discovering that expands well past scholastic learning into the fields of social, creative, physical, otherworldly and passionate advancement of a youngster.</p>
                    </div>

                    <div className={classes.card2}>
                        <img src={img2} className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Personal attention</p>
                        <p className={classes.cardTxt}>We accentuate little classroom where every youngster has the privilege to communicate their independence. We support every one of our understudies to be their own exceptional selves, as opposed to being packed into a solitary huge classroom that just makes studying.</p>
                    </div>

                    <div className={classes.card3}>
                        <img src={img3} className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Children friendly classrooms</p>
                        <p className={classes.cardTxt}>Our classrooms provide an immersive learning environment for each and every child. This immersive learning environment, where everything a child sees, hears, or plays with, is geared towards developing a particular set of skills.</p>
                    </div>
                </div>
            </div>

            <div className={classes.programHold}>
                <p className={classes.programHead}>Some of our popular
                    <span className={classes.headcolor}> Programs</span>:</p>

                <div className={classes.programCardHold}>
                    <div className={classes.programCard1}>
                        <img src={img1} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Playgroup</p>
                        <p className={classes.cardTxt}>This program is, designed to cater to the learning requirements of children between the age group of 2-3 years. We give special care and attention to these children and help them overcome anxiety.</p>
                    </div>
                    <div className={classes.programCard2}>
                        <img src={abc} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Nursery</p>
                        <p className={classes.cardTxt}>This program is designed to cater to the developmental requirements of children between 3-4yrs. We help your children building a strong base in phonics, to improve their reading skills.</p>
                    </div>
                    <div className={classes.programCard3}>
                        <img src={board} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Jr. Kids group</p>
                        <p className={classes.cardTxt}>This program prepares children for the next level of socio-emotional development and academic, social, and physical skills. The levels of facilitating, learning, and participation are higher.</p>
                    </div>
                    <div className={classes.programCard4}>
                        <img src={blackboard} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Sr. Kids group</p>
                        <p className={classes.cardTxt}>This program prepares children for formal schooling and addition, subtraction. Children develop excellent reading skills, through an emphasis on consonant blend sounds that they hear.</p>
                    </div>
                    <div className={classes.programCard5}>
                        <img src={baby} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Edu-daycare</p>
                        <p className={classes.cardTxt}>Edu-daycare is a next-level daycare Program that emphasizes the overall growth of children which includes moral storytelling sessions,
                            rhymes sessions, and learning games.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nursery;