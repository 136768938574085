import classes from './Footer.module.css';
import loc from './asset/location.png';
import clock from './asset/clock.png';
import connected from './asset/group.png';
import phone from './asset/smartphone.png';
import palm from './asset/palm.png';

import linkedIn from './asset/linkedin.png';
import facebook from './asset/facebook.png';
import insta from './asset/instagram.png';
import hrt from './asset/heart.gif';

const Footer = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.hold2}>
                <div className={classes.h1}>
                    <div className={classes.h1In}>
                        <img src={loc} className={classes.icon}></img>
                        <p className={classes.head}>Location</p>
                    </div>

                    <p className={classes.txt}>Dhumal Building, above OFC Fitness Gym,</p>
                    <p className={classes.txt}>Next To Telco Colony, Jambhulwadi Road,</p>
                    <p className={classes.txt}>Dattnagar Katraj, Pune- 411046</p>
                </div>

                <div className={classes.h1}>
                    <div className={classes.h1In}>
                        <img src={clock} className={classes.icon}></img>
                        <p className={classes.head}>Opening Hours</p>
                    </div>
                    <p className={classes.txt}>Monday-Saturday</p>
                    <p className={classes.txt}> 10:00am to 4:00pm</p>
                </div>

                <div className={classes.h1}>
                    <div className={classes.h1In}>
                        <img src={phone} className={classes.icon}></img>
                        <p className={classes.head}>Contact Us</p>
                    </div>
                    <p className={classes.txt}>+91 9545507282</p>
                    <p className={classes.txt}>kalpatarupreschool@gmail.com</p>

                </div>

                <div className={classes.h1}>
                    <div className={classes.h1In}>
                        <img src={connected} className={classes.icon}></img>
                        <p className={classes.head}>Stay Connected</p>
                    </div>
                    <p className={classes.txt}>
                        <a target="_blank" href='https://www.facebook.com/profile.php?id=100090332185959'><img src={facebook} className={classes.icon2}></img></a>
                        <a target="_blank" href='https://www.instagram.com/kalpataru_preschool/'><img src={insta} className={classes.icon2}></img></a>
                        <a target="_blank" href='https://www.linkedin.com/in/kalpataru_preschool/'> <img src={linkedIn} className={classes.icon2}></img></a>
                    </p>

                </div>
            </div>
            <div className={classes.palmHold}>
                <img src={palm} className={classes.palm}></img>
                <img src={palm} className={classes.palm}></img>
                <img src={palm} className={classes.palm}></img>
            </div>

            <div>
                <hr></hr>
                <p className={classes.company}> Copyright &#169; 2023 Kalpataru Preschool | Made with
                    <img src={hrt} className={classes.hrt}></img> by Cortica Web Solutions Pvt. Ltd</p>
            </div >
        </div >
    )
}

export default Footer;