import classes from './Test.module.css';
import children from './children.jpg';
import TestSlide from './TestSlide';

const Test = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.left}>
                <img src={children} className={classes.image}></img>

            </div>
            <div className={classes.right}>
                <p className={classes.head}>What
                    <span className={classes.col}> Parents</span> Say</p>

                <div>
                    <TestSlide></TestSlide>
                </div>
            </div>
        </div>
    )
}

export default Test;