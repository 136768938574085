import classes from './Card1.module.css';
import { Link } from 'react-router-dom';

import img1 from '../asset/elephant.png';
import img2 from '../asset/crayons.png';
import img3 from '../asset/abc.png';
import img4 from '../asset/hobby.png';

const Card1 = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.card}>
                <img src={img1} className={classes.icon}></img>
                <p className={classes.head}>Daycare</p>
                <p className={classes.txt}>Equipped with the highest safety standards, we ensure a holistic care equipped with essential learning for your child that shows noticeable results. We provided best inspiring learning environments and Skilled teachers.</p>
                <Link to='/daycare' span={true} smooth={true}>
                    <button className={classes.btn}>Read More</button>
                </Link>
            </div>

            <div className={classes.card1}>
                <img src={img2} className={classes.icon}></img>
                <p className={classes.head}>Early learning</p>
                <p className={classes.txt}>The Start Early program assures a smooth transition into preschool for toddlers. This program will also stimulate the children's minds with songs and poems. We provided best inspiring learning environments and Skilled teachers.</p>
                <Link to='/playgroup' span={true} smooth={true}>
                    <button className={classes.btn}>Read More</button>
                </Link>
            </div>

            <div className={classes.card2}>
                <img src={img3} className={classes.icon}></img>
                <p className={classes.head}>Preschool</p>
                <p className={classes.txt}>Preschool lays the foundation for the preschool journey. Kids are exposed to activities that help them to eat independently, make friends. We provided best inspiring learning environments and Skilled teachers.</p>
                <Link to='/nursery' span={true} smooth={true}>
                    <button className={classes.btn}>Read More</button>
                </Link>
            </div>

            <div className={classes.card4}>
                <img src={img4} className={classes.icon}></img>
                <p className={classes.head}>Hobby Classes</p>
                <p className={classes.txt}>A hobby is considered to be a day today activity that is done for enjoyment. Hobbies include Dance, Music, Singing and engaging in creative and artistic pursuits, playing sports, or pursuing other amusements. We provided best inspiring learning environments and Skilled teachers.</p>
                <Link to='/hobby' span={true} smooth={true}>
                    <button className={classes.btn}>Read More</button>
                </Link>
            </div>
        </div>
    )
}

export default Card1;