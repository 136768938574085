import { Component } from "react";
import './Navi.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';


class Navigation extends Component {
    state = { clicked: false };
    handleClick = () => {
        this.setState({
            clicked: !this.state.clicked
        })
    };
    render() {
        return (<div>

            <div className="navHold">

                <div>

                    <nav className="navbarItems">

                        <img src={logo} className="comlogo2"></img>
                        <div className="menu-icons" onClick={this.handleClick}>
                            <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                        </div>
                        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                            <li className="list"><Link className="linker" to='/' span={true} smooth={true}>Home</Link></li>
                            <li className="list"><Link className="linker" to='/about' span={true} smooth={true}>About Us</Link></li>
                            <li >
                                <div class="dropdown">
                                    <p class="dropdownContent">Our Programs</p>
                                    <div class="dropdown-content">
                                        <Link to='/playgroup'>Playgroup</Link>
                                        <Link to='/nursery'>Nursery</Link>
                                        <Link to='/junior'>Junior K.G</Link>
                                        <Link to='/senior'>senior K.G</Link>
                                        <Link to='/daycare'>Daycare</Link>

                                    </div>
                                </div>

                            </li>
                            <li className="list"><Link className="linker" to='/hobby' span={true} smooth={true}>Hobby Class</Link></li>
                            <li className="list"><Link className="linker" to='/gallery' span={true} smooth={true}>Gallery</Link></li>

                            <li className="list"><Link className="linker" to='/contact' span={true} smooth={true}>Contact Us</Link></li>


                        </ul>
                    </nav>
                </div>
            </div >


        </div>
        )
    }
};

export default Navigation;

