import classes from './Why.module.css';
import Card from './Card';
import clock from './asset/clock.png';
import door from './asset/opened-door-aperture.png';
import connection from './asset/group.png';
import curriculum from './asset/profiles.png';
import dance from './asset/female-flamenco-dancer.png';
import tennis from './asset/tennis.png';

const Why = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Why Choose
                <span className={classes.headcolor}> Kalpataru Preschool</span></p>

            <p className={classes.txt}>Our schools offer adaptable timetables that fit school to the understudy—rather than fitting the understudy to the school. All things considered, everybody can profit by the adaptability of virtual schools. With this model, it’s easy to oblige physical checkups, chipping in, low maintenance occupations, and different duties. Furthermore, understudies changing from being self-taught may discover online school to be a decent decision—and a smooth progress.</p>

            <p className={classes.txt}>The adaptable timetable likewise permits understudies to move at their own movement. Understudies at virtual schools don’t need to trust that different understudies will get up to speed or stress over racing through a troublesome idea. This adaptable movement takes into account dominance of material on your understudy’s terms.</p>

            <div className={classes.cardHold}>
                <Card image={clock} head='Less Distractions'
                    txt='We train child to gain instant presence & take right decisions'></Card>

                <Card image={door} head='Open door for Struggling Students'
                    txt='We follow opendoor policy, hence we are always open for kids struggling in their education.'></Card>

                <Card image={connection} head='Better Connections with Family, Instructors'
                    txt='Timely & proper communication between parents & teachers are important for overall development of kids.'></Card>

                <Card image={curriculum} head='Proven Curriculum'
                    txt="Kalpataru strictly follows NCERT's guildlines for preschool education"></Card>

                <Card image={tennis} head='Sports Program'
                    txt='All year round, there are extra curriculum activities including sports to make kids competative & active.'></Card>

                <Card image={dance} head='Dance Class'
                    txt='Hobbies that are developed from an early age remain with us till longer and help in spending leisure time productively'></Card>
            </div>
        </div >
    )
}

export default Why;