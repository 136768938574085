import classes from './About1.module.css';
import img1 from './asset/lotus.png';
import img2 from './asset/add-user.png';
import img3 from './asset/user.png';

import board from '../../pages/asset/chalkboard.png';
import blackboard from '../../pages/asset/blackboard.png';
import baby from '../../pages/asset/baby-girl.png';
import abc from '../Home/asset/abc.png';
import block from '../../pages/asset/blocks.png';

const About1 = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.abt}>ABOUT US</p>
            <p className={classes.head1}>An education for independence,</p>
            <p className={classes.head2}>at school and beyond</p>

            <div className={classes.cardHold}>
                <div className={classes.card}>
                    <img src={img1} alt='' className={classes.cardImg}></img>
                    <p className={classes.cardHd}>Our Mission</p>
                    <p className={classes.cardtxt}>Our singular mission at Kalptaru is to guide and empower each child as it grows in its independence.</p>
                </div>

                <div className={classes.card}>
                    <img src={img2} alt='' className={classes.cardImg}></img>
                    <p className={classes.cardHd}>Enrollment</p>
                    <p className={classes.cardtxt}>The kindergarden is in the system of city subsidies and the enrollment of children is done all year round.</p>
                </div>

                <div className={classes.card}>
                    <img src={img3} alt='' className={classes.cardImg}></img>
                    <p className={classes.cardHd}>Program</p>
                    <p className={classes.cardtxt}>Responsibility, independence and self-confidence are what is achieved by using Montessori pedagogy.</p>
                </div>
            </div>

            <div className={classes.expHold}>
                <p className={classes.expHd}>Explore our programs</p>
                <p className={classes.expHd2}>We are currently accepting registrations for all our programs. Your child can start right away or join the waitlist for classes which are full. We are pleased to offer affiliated at-home programming while you wait to join us on campus.</p>

                <div className={classes.programHold}>

                    <div className={classes.programCardHold}>
                        <div className={classes.programCard1}>
                            <img src={block} alt='' className={classes.cardImg}></img>
                            <p className={classes.cardHead}>Playgroup</p>
                            <p className={classes.cardTxt}>This program is, designed to cater to the learning requirements of children between the age group of 2-3 years. We give special care and attention to these children and help them overcome anxiety.</p>
                        </div>
                        <div className={classes.programCard2}>
                            <img src={abc} alt='' className={classes.cardImg}></img>
                            <p className={classes.cardHead}>Nursery</p>
                            <p className={classes.cardTxt}>This program is designed to cater to the developmental requirements of children between 3-4yrs. We help your children building a strong base in phonics, to improve their reading skills.</p>
                        </div>
                        <div className={classes.programCard3}>
                            <img src={board} alt='' className={classes.cardImg}></img>
                            <p className={classes.cardHead}>Jr. Kids group</p>
                            <p className={classes.cardTxt}>This program prepares children for the next level of socio-emotional development and academic, social, and physical skills. The levels of facilitating, learning, and participation are higher.</p>
                        </div>
                        <div className={classes.programCard4}>
                            <img src={blackboard} alt='' className={classes.cardImg}></img>
                            <p className={classes.cardHead}>Sr. Kids group</p>
                            <p className={classes.cardTxt}>This program prepares children for formal schooling and addition, subtraction. Children develop excellent reading skills, through an emphasis on consonant blend sounds that they hear.</p>
                        </div>
                        <div className={classes.programCard5}>
                            <img src={baby} alt='' className={classes.cardImg}></img>
                            <p className={classes.cardHead}>Edu-daycare</p>
                            <p className={classes.cardTxt}>Edu-daycare is a next-level daycare Program that emphasizes the overall growth of children which includes moral storytelling sessions,
                                rhymes sessions, and learning games.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.imgHold}>
                <div className={classes.imgHoldIn}>
                    <p className={classes.imgHold1}>OUR METHOD</p>
                    <p className={classes.imgHold2}>Education For Life</p>
                    <p className={classes.imgHold3}>Our education provides the freedom and guidance needed to construct a sense of self and</p>
                    <p className={classes.imgHold4}>purpose through joyful, meaningful work.</p>
                </div>
            </div>
        </div>
    )
}

export default About1;