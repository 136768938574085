import { useEffect } from "react";
import About1 from "../components/Abourt/About1";

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <About1></About1>
        </div>
    )
}

export default AboutPage;