import React, { Component } from "react";
import Slider from "react-slick";
import classes from './Slide.module.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";

export default class Slide extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 6000,
            cssEase: "linear"
        };
        return (<div className={classes.mainhold}>
            <div className={classes.hold}>

                <Slider {...settings}>
                    <div>
                        <Slide1></Slide1>
                    </div>
                    <div>
                        <Slide2></Slide2>
                    </div>
                    <div>
                        <Slide3></Slide3>
                    </div>

                </Slider>
            </div>
        </div>
        );
    }
}