import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import HomePage from '../src/pages/HomePage';
import AboutPage from '../src/pages/AboutPage';
import Contactpage from '../src/pages/Contactpage';
import Root from './pages/Root';

import Playgroup from './pages/Playgroup';
import Nursery from './pages/Nursery';
import Junior from './pages/Junior';
import Senior from './pages/Senior';
import Daycare from './pages/Daycare';
import Gallery from './pages/Gallery';
import Hobby from './components/Hobby/Hobby';

const router = createBrowserRouter([{
  path: '/',
  element: <Root></Root>,

  children: [
    { path: '/', element: <HomePage></HomePage> },
    { path: '/about', element: <AboutPage></AboutPage> },
    { path: '/contact', element: <Contactpage></Contactpage> },
    { path: '/playgroup', element: <Playgroup></Playgroup> },
    { path: '/nursery', element: <Nursery></Nursery> },
    { path: '/junior', element: <Junior></Junior> },
    { path: '/senior', element: <Senior></Senior> },
    { path: '/daycare', element: <Daycare></Daycare> },
    { path: '/hobby', element: <Hobby></Hobby> },
    { path: '/gallery', element: <Gallery></Gallery> },


  ]
}]);

const App = () => {
  return <RouterProvider router={router}></RouterProvider>

};

export default App;