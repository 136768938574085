import { Outlet } from "react-router-dom";
import Navigation from "../components/Navbar/Navi";
import Footer from "../components/Footer/Footer";
import NewWhatsapp from "../components/whatsapp/What";



const Root = () => {
    return <div>

        <Navigation></Navigation>
        <Outlet></Outlet>
        <NewWhatsapp></NewWhatsapp>
        <Footer></Footer>


    </div>
};

export default Root;