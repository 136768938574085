import { useEffect } from 'react';
import classes from './Daycare.module.css';
import play1 from './asset/daycare2.webp';
import { Link } from 'react-router-dom';

import img1 from './asset/blocks.png';
import img2 from './asset/care.png';
import img3 from './asset/chalkboard.png';
import board from './asset/chalkboard.png';
import blackboard from './asset/blackboard.png';
import baby from './asset/baby-girl.png';
import abc from '../components/Home/asset/abc.png';

const Daycare = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={classes.hold}>
            <div className={classes.headHold}>
                <p className={classes.head}>Daycare</p>
            </div>

            <div className={classes.divhold}>
                <div className={classes.left}>
                    <img src={play1} className={classes.play1}></img>
                </div>
                <div className={classes.right}>
                    <p className={classes.head1}>About Our
                        <span className={classes.headcolor}> daycare</span></p>
                    <p className={classes.txt1}>Daycare is a next-level daycare Program that emphasizes the overall growth of children which includes moral storytelling sessions, rhymes sessions and learning games, and healthy meals and snacks.</p>

                    <Link to='/contact' className='linker'>
                        <button className={classes.btn}>Enroll Now</button>
                    </Link>
                </div>
            </div>

            <div className={classes.feeHold}>
                <p className={classes.feeHead}>What You
                    <span className={classes.headcolor}> Get</span> For The
                    <span className={classes.headcolor}> Fees</span> You Pay</p>

                <div className={classes.cardHold}>
                    <div className={classes.card1}>
                        <img src={img1} className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Holistic Curriculum</p>
                        <p className={classes.cardTxt}>as the name proposes covers a wide scope of abilities, showing apparatuses, objectives and desires for kids' discovering that expands well past scholastic learning into the fields of social, creative, physical, otherworldly and passionate advancement of a youngster.</p>
                    </div>

                    <div className={classes.card2}>
                        <img src={img2} className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Personal attention</p>
                        <p className={classes.cardTxt}>We accentuate little classroom where every youngster has the privilege to communicate their independence. We support every one of our understudies to be their own exceptional selves, as opposed to being packed into a solitary huge classroom that just makes studying.</p>
                    </div>

                    <div className={classes.card3}>
                        <img src={img3} className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Children friendly classrooms</p>
                        <p className={classes.cardTxt}>Our classrooms provide an immersive learning environment for each and every child. This immersive learning environment, where everything a child sees, hears, or plays with, is geared towards developing a particular set of skills.</p>
                    </div>
                </div>
            </div>


            <div className={classes.aptitudeHold}>
                <p className={classes.aptitudeHead}>Our
                    <span className={classes.headcolor}> Benefits</span> </p>


                <p className={classes.aptitudeTxt}>Edu-care is something that is more than a regular daycare. We think parents should not pay for a daycare that only feeds their child under the name of daycare. Daycare definitely more than just feeding and playing it should include the upbringing as well.</p>

                <div className={classes.txtHold}>
                    <div className={classes.aptitudeLeft}>
                        <ul className={classes.listHold}>
                            <li className={classes.list}>Moral story sessions: a story session is something that every child loves and it really shows positive impct on childrens's personality</li>
                            <li className={classes.list}>Rhyme sessions: Rhymes are proven to be engaging and boredom killing for kids. regular rhyme sessions incredibly boost child's memory.</li>
                            <li className={classes.list}>Drawing sessions: a drawing session is the most favourite session of every child. our wisely designed drawing sessions improve the imagination power of children.
                            </li>
                        </ul>
                    </div>
                    <div className={classes.aptitudeRight}>
                        <ul className={classes.listHold}>
                            <li className={classes.list}>Healthy meal: instead of just Daa-rice we feed children with a whole diet that contains all the essential nutritions.</li>
                            <li className={classes.list}>Snacks: we feed children with the best possible snacks recommended by the Professional nutritionist. It doens't conta</li>
                            <li className={classes.list}>Play sessions: a play session is wisely designed to focus on the interbody syncronizations on a child and the reactions coming from it. all the games empower this syncronizatins in an effective manner.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={classes.programHold}>
                <p className={classes.programHead}>Some of our popular
                    <span className={classes.headcolor}> Programs</span>:</p>

                <div className={classes.programCardHold}>
                    <div className={classes.programCard1}>
                        <img src={img1} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Playgroup</p>
                        <p className={classes.cardTxt}>This program is, designed to cater to the learning requirements of children between the age group of 2-3 years. We give special care and attention to these children and help them overcome anxiety.</p>
                    </div>
                    <div className={classes.programCard2}>
                        <img src={abc} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Nursery</p>
                        <p className={classes.cardTxt}>This program is designed to cater to the developmental requirements of children between 3-4yrs. We help your children building a strong base in phonics, to improve their reading skills.</p>
                    </div>
                    <div className={classes.programCard3}>
                        <img src={board} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Jr. Kids group</p>
                        <p className={classes.cardTxt}>This program prepares children for the next level of socio-emotional development and academic, social, and physical skills. The levels of facilitating, learning, and participation are higher.</p>
                    </div>
                    <div className={classes.programCard4}>
                        <img src={blackboard} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Sr. Kids group</p>
                        <p className={classes.cardTxt}>This program prepares children for formal schooling and addition, subtraction. Children develop excellent reading skills, through an emphasis on consonant blend sounds that they hear.</p>
                    </div>
                    <div className={classes.programCard5}>
                        <img src={baby} alt='' className={classes.cardImg}></img>
                        <p className={classes.cardHead}>Edu-daycare</p>
                        <p className={classes.cardTxt}>Edu-daycare is a next-level daycare Program that emphasizes the overall growth of children which includes moral storytelling sessions,
                            rhymes sessions, and learning games.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Daycare;