import classes from './Slide2.module.css';
import rocket from './asset/rocket.png';

const Slide2 = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.txthold}>
                <p className={classes.head1}>Progressive education with </p>
                <p className={classes.head2}> meaningful learning.</p>
            </div>

        </div>
    )
}

export default Slide2;