import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TestCard from "./TestCard";
import classes from './TestSlide.module.css';

import man from './menFace.jpg';
import t1 from '../asset/t1.jpg';
import t2 from '../asset/t2.avif';
import t3 from '../asset/t3.jpg';
import t4 from '../asset/t4.jpg';
import t5 from '../asset/t4.jpg';
import t10 from '../asset/t10.jpg';
import t7 from '../asset/t7.webp';

export default class TestSlide extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 5000,
            cssEase: "linear"
        };
        return (<div className={classes.hold}>
            <div>

                <Slider {...settings}>
                    <div>
                        <TestCard txt='Kalptaru immensely contributed to the holistic development of my kid. I am very happy to have chosen Kalptaru to help hone his talents & prepare them for todays competitive world'
                            name='Akshay Sinha' image={man}></TestCard>
                    </div>
                    <div>
                        <TestCard txt='The confidence, trust & hand holding given by the school, helped my child to grow the wings of confidence.'
                            name='Sneha Kadam' image={t1}></TestCard>
                    </div>
                    <div>
                        <TestCard txt='An outstanding school which I recommend highly to all who are in need of a preschool.My son has been extra-ordinary happy here.'
                            name='Mukesh Patil' image={t4}></TestCard>
                    </div>

                    <div>
                        <TestCard txt='The preschool is a sweet, caring, nurturing and thoughtful environment. The teachers are wonderful and genuine and they are very hands on with the kids. '
                            name='Anant Bhalerao' image={t3}></TestCard>
                    </div>

                    <div>
                        <TestCard txt='Experienced staff, good nature, good for bright future, my daughter is 3 years only writes all the things extra-ordinary. Very happy for our decision. Thanks to ‘Kalpataru team’.!!!'
                            name='Neha Waghmare' image={t2}></TestCard>
                    </div>

                    <div>
                        <TestCard txt='Mrs. Anuja Mane and her staff are extremely caring and insightful. Thanks to “Kalpataru team”, for making our kids happy &good learner.'
                            name='Sakshi Gore' image={t1}></TestCard>
                    </div>

                    <div>
                        <TestCard txt='We love the preschool because of the teachers and ‘kalpataru team’. They are kind and respectful.'
                            name='Mahesh Kumar' image={t7}></TestCard>
                    </div>

                    <div>
                        <TestCard txt='This is very well thought out preschool. The children get a well-rounded mix of experiences and multi-cultural activities.'
                            name='Sushi Agarwal' image={t10}></TestCard>
                    </div>


                </Slider>
            </div>
        </div>
        );
    }
}