import { useEffect } from 'react';
import classes from './Hobby.module.css';


const Hobby = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={classes.hold}>
            <div className={classes.headHold}>
                <p className={classes.head}>Hobby Classes</p>
            </div>

            <p className={classes.head1}>Let Out the
                <span className={classes.head1color}> Imagination</span> and
                <span className={classes.head1color}> Creativity</span></p>

            <p className={classes.head2}>Students are given that chance in hobby clubs where they give expression to their creative soul. It helps them express their thoughts and feelings in a better manner and also become honest and receptive individual free from narrow environmental vices and prejudices.</p>

            <div className={classes.imgHold}>
                <div className={classes.card1}>
                    <p className={classes.cardTxt}>Dance Class</p>
                </div>
                <div className={classes.card2}>
                    <p className={classes.cardTxt}>Music-Instrumental Classes</p>
                </div>
                <div className={classes.card3}>
                    <p className={classes.cardTxt}>Drawing Classes</p>
                </div>



                <div className={classes.card6}>
                    <p className={classes.cardTxt}>Handwriting Classes</p>
                </div>
                <div className={classes.card7}>
                    <p className={classes.cardTxt}>Abacus Classes</p>
                </div>
                <div className={classes.card8}>
                    <p className={classes.cardTxt}>Phonics & Vedic Maths</p>
                </div>
            </div>
        </div>
    )
}

export default Hobby;