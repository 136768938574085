import { useEffect } from "react";
import Card1 from "../components/Home/Card1/Card1";
import Mission from "../components/Home/Mission/Mission";
import Why from "../components/Home/Why/Why";
import Test from "../components/Home/Testimonial/Test";
import Wonder from "../components/Home/Wonderland/Wonder";
import Team from "../components/Home/Team/Team";
import Admission from "../components/Home/Admission/Admission";
import AdmissionFeature from "../components/Home/AdmissionFeature";
import Contact from "../components/Home/Contact/Contact";
import Slide from "../components/Home/Mainslider/Slide";

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (

        <div>
            <Slide></Slide>
            <Card1></Card1>
            <Mission></Mission>
            <Why></Why>
            <Test></Test>
            <Wonder></Wonder>
            <Team></Team>
            <Admission></Admission>
            <AdmissionFeature></AdmissionFeature>
            <Contact></Contact>
        </div>
    )
}

export default HomePage;