import classes from './Team.module.css';

import jyoti from '../asset/Mrs. Jyoti Yogesh Borse.jpg';
import priyanka from '../asset/Mrs. Priyanka Nilesh Pardeshi.jpg';
import tabasun from '../asset/Mrs. Tabassun Sameer .jpg';
import tejashwani from '../asset/Mrs. Tejashwani Pradeep Bhandhari.jpg';
import sneha from '../asset/Mrs.Sneha Rakesh Chavan.jpg';

const Team = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Our Team</p>
            <p className={classes.head2}>When your aim is to make your child better, faster and smarter, you need the best people driving your vision forward.</p>

            <div className={classes.picContainer}>
                <div className={classes.picHold}>
                    <img src={jyoti} className={classes.image}></img>
                    <p className={classes.name}>Mrs. Jyoti Yogesh Borse</p>
                </div>

                <div className={classes.picHold}>
                    <img src={priyanka} className={classes.image}></img>
                    <p className={classes.name}>Mrs. Priyanka Nilesh Pardeshi</p>
                </div>

                <div className={classes.picHold}>
                    <img src={tabasun} className={classes.image}></img>
                    <p className={classes.name}>Mrs. Tabassun Sameer</p>
                </div>

                <div className={classes.picHold}>
                    <img src={tejashwani} className={classes.image}></img>
                    <p className={classes.name}>Mrs. Tejashwani Pradeep Bhandhari</p>
                </div>

                <div className={classes.picHold}>
                    <img src={sneha} className={classes.image}></img>
                    <p className={classes.name}>Mrs.Sneha Rakesh Chavan </p>
                </div>
            </div>
        </div>
    )
}

export default Team;