import { useEffect } from 'react';
import classes from './Contactpage.module.css';
import owl from './asset/owl.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contactpage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const notify = (event) => {

        toast("Message Send, Thankyou!")
    };

    return (
        <div className={classes.hold}>
            <p className={classes.head}>Let's Get In Touch</p>
            <p className={classes.head1}>Kalpataru Preschool</p>

            <div className={classes.divHold}>
                <div className={classes.left}>
                    <form action='https://formsubmit.co/kalpatarupreschool@gmail.com' method='POST'>
                        <input className={classes.in} type='text' placeholder='Name' name='name' required='true'></input><br></br>
                        <input className={classes.in} type='text' placeholder='Mobile Number' name='mobile number' required='true'></input><br></br>
                        <input className={classes.in} type='text' placeholder='Email Id' name='email id' required='true'></input><br></br>
                        <textarea className={classes.in} placeholder='Message' name='message' required='true'></textarea><br></br>
                        <input type="hidden" name="_captcha" value='false'></input>
                        <button type='submit' className={classes.btn}>Submit</button>
                        <ToastContainer toastStyle={{ backgroundColor: "yellow" }} />
                    </form>
                </div>
                <div className={classes.right}>
                    <img src={owl} alt='' className={classes.owl}></img>
                </div>
            </div>
        </div >
    )
}

export default Contactpage;