import classes from './Slide3.module.css'

const Slide3 = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.txthold}>
                <p className={classes.head1}>Kalpataru Prepares kids For Their</p>
                <p className={classes.head2}>Bright Future</p>
            </div>
        </div>
    )
}

export default Slide3;